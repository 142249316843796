@import url("https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap");
$font: "Press Start 2P", serif;
@import "glitch";

* {
  image-rendering: pixelated;
  font-smooth: never;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: none;
}

.window .title-bar,
img,
button,
.desktop-icons {
  user-select: none;
}

html,
body {
  touch-action: manipulation;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
p {
  margin: 0;
  padding: 0 0 1em;
}
.App {
  height: 100vh;
  background-color: black;
  &:before {
    display: none;
    z-index: 999;
    pointer-events: none;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.02;
    background: rgba(white, 0);
    background: linear-gradient(
      0deg,
      rgba(white, 0) 0%,
      rgba(white, 1) 50%,
      rgba(white, 0) 100%
    );
    animation: framerate 600ms infinite both;
    mix-blend-mode: screen;
  }
}
@keyframes framerate {
  0% {
    transform: translateY(-100%);
  }
  80% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(100%);
  }
}
button {
  cursor: pointer;
}

.startupscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../images/clouds.jpg") no-repeat center;
  background-size: cover;
  animation: fadeIn 10ms linear 200ms 1 both;
  overflow: hidden;
  img {
    display: block;
    max-width: 95%;
  }
  .bar{
    position:absolute;
    bottom:0;
    left:0;
    width:400%;
    height:2rem;
    background: linear-gradient(90deg, #5b89c0, #acbac5, #5b89c0, #acbac5, #5b89c0);
    animation: bgMove 1000ms linear infinite;
  }
}
@keyframes bgMove {
  0% { transform:translate(0%,0); }
  100% { transform:translate(-50%,0); }
}
.home {
  height: 100vh;
  background: #008080;
  overflow: hidden;
  animation: fadeIn 1000ms linear 500ms 1 both;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.video {
  position: relative;
  .video-title {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 12px;
    color: white;
    background-color: rgba(black, 0.7);
  }
  .video-next {
    cursor: pointer;
    z-index: 3;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 12px;
    color: white;
  }
  .video-filter {
    position: relative;
    overflow: hidden;
    iframe {
      filter: brightness(130%);
      transform:scale(1.35);
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAEAQMAAACTPww9AAAABlBMVEUAAAAAAAClZ7nPAAAAAXRSTlMAQObYZgAAAA1JREFUCNdjOACEQAAACQgBgeEHEZ0AAAAASUVORK5CYII=);
      background-size: 2px 2px;
    }
  }
}

.start-bar {
  z-index: 3;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  box-shadow: inset -1px -1px #0a0a0a, inset 1px 1px #dfdfdf,
    inset -2px -2px grey, inset 2px 2px #fff;
  background: silver;
  padding: 3px;
  display: flex;

  button{
    padding:0 8px;
  }

  .start-button {
    position: relative;
    min-width: 0;
    width:80px;
  }
  .start-bar-icon {
    display: inline-block;
    vertical-align: middle;
    width: 1.25em;
    margin-right: 0.25rem;
    img {
      display: block;
      width: 100%;
    }
  }
  .time {
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 7px 12px 5px;
    background: silver;
    box-shadow: inset -1px -1px #ffffff, inset 1px 1px #0a0a0a,
      inset -2px -2px #dfdfdf, inset 2px 2px #808080;
  }
  ul {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    overflow: hidden;
    padding: 0;
    margin: 0;
    li {
      display: block;
      button{
        display: block;
        white-space: nowrap;
      }
    }
  }
}

.screensaver {
  display: block;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
  }
}

canvas{
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

@keyframes blink {
  0% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  100% {
    visibility: hidden;
  }
}

.BIOS {
  height: 100vh;
  font-family: $font;
  background: black;
  color: #808080;
  line-height: 2;
  font-size: 12px;
  strong {
    color: white;
  }

  .container {
    $padding: 2rem;
    position: absolute;
    top: $padding;
    bottom: $padding;
    left: $padding;
    right: $padding;
  }

  .instructions {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .ribbon {
    display: block;
    float: left;
    margin-right: 1rem;
    width: 2rem;
  }

  .EPA {
    display: block;
    float: right;
    max-width: 42%;
  }

  .typing-hack {
    display: inline-block;
    position: relative;
    .text {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
    .typing {
      position: relative;
      z-index: 2;
      color: transparent;
      display: inline;
      background-image: linear-gradient(to bottom, #000000 0, #000000 100%);
      background-size: 0% 100%;
      background-repeat: no-repeat;
      background-position: 100% 100%;
      animation: typing 2s linear 1 both;
      &:after {
        content: "";
        margin-left: 1em;
        display: inline-block;
        width: 1em;
        height: 4px;
        background-color: white;
        animation: blink 600ms linear infinite, typingBlinker 2s linear 1 both;
      }
    }
    &.fast .typing {
      animation-duration: 1s;
    }
  }
}
@keyframes typing {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 0% 100%;
  }
}
@keyframes typingBlinker {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.bluescreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: $font;
  color: #ffffff;
  line-height: 2;
  font-size: 10px;
  background-color: #2400b8;
  .content {
    transform: scaleY(1.1); // this font is hard to read
    padding: 0 1rem;
    max-width: 690px;
    text-align: center;
    strong {
      display: inline-block;
      margin: 0 auto 1rem;
      padding: 2px 10px;
      color: #2400b8;
      background-color: #a8a8a8;
    }
    p {
      text-align: left;
      &:last-of-type {
        margin-top: 2rem;
        &:after {
          content: "";
          margin-left: 1em;
          display: inline-block;
          width: 1em;
          height: 4px;
          background-color: white;
          animation: blink 600ms linear infinite;
        }
      }
    }
    a {
      display: inline-block;
      text-decoration: underline;
      padding: 1px 0;
      color: white;
    }
  }
}
img {
  pointer-events: none;
}

.desktop-icons {
  max-height: 80vh;
  max-width: 100vw;
  user-select: none;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    display: block;
    width: 6.5rem;
    height: 7rem;
  }
}
.desktop-icon {
  display: block;
  margin: 0.5rem 0.5rem;
  padding: 0.25rem;
  &:hover label {
    border-color: whiteSmoke;
  }

  img {
    display: block;
    width: 48px;
    margin: 0 auto 1rem;
  }
  label {
    display: block;
    padding: 0;
    line-height: 0.6;
    font-size: 0.75rem;
    line-height: 1.2;
    text-align: center;
    color: white;
    border: 2px dotted transparent;
  }
}

.window {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  &.current{
    z-index: 2;
  }
  .resizable-handle {
    z-index: 9;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAAAAAAevcqWAAAAOElEQVQI133MsQ0AIAwDQQ//g7GZKZIAbnB3kvVaOf3ttFEybMIGnp6Bp1c8/+Z42D6s3qWVREk2kAqiAaMM6bsAAAAASUVORK5CYII=");
  }

  .title-bar {
    position: relative;
    display: block;
    .title-bar-icon {
      position: absolute;
      left: 2px;
      top: 2px;
      width: 14px;
      margin-right: 0.5em;
      img {
        display: block;
        width: 100%;
      }
    }
    .title-bar-text {
      display: block;
      margin: 0 20px 0 15px;
      overflow: hidden;
    }
    .draggable-handle {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    .title-bar-controls {
      position: absolute;
      z-index: 2;
      right: 2px;
      top: 2px;
    }
  }

  img {
    max-width: 20rem;
  }
}
.delayShow {
  animation: delayShow 10ms linear 1 both;
}
@keyframes delayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@for $i from 0 through 20 {
  .delay-#{$i} {
    animation-delay: #{1000+ ($i * 200)}ms;
  }
}

.window {
  .window {
    transform: translate(10px, 10px);
  }
  &.internet-explorer,
  &.recycling-bin,
  &.poolside-tv,
  &.creditstxt,
  &.media-player,
  &.poolside-paint,
  &.passwordstxt {
    .window-body {
      margin: 0;
    }
  }

  &.internet-explorer{
    >div {
      cursor: pointer;
    }
  }

  &.internet-explorer{
    max-width: 80vw;
    width: 60rem;
    img {
      display: block;
      max-width: none;
      width: 100%;
      border: 0;
      margin: 0;
    }
  }

  &.virus-alert{
    transform-origin:50% 50%;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  }
}
@keyframes shake {
  10%, 90% {      left:-1px; top:1px; }
  20%, 80% {      left: 2px; top:2px;}
  30%, 50%, 70% { left: -4px;top:-2px;}
  40%, 60% {      left: 4px; top:4px;}
}

.media-player-inner{
  overflow: hidden;
  &.ejected{
    .container{
      transform:translate(0%,-100%);
    }
  }

  .container{
    position:relative;
    transition:transform 400ms ease-in-out;
    .burt{
      position:absolute;
      top:100%;
      left:0;
      right:0;
    }
  }
}


iframe {
  display: block;
  max-width: 100%;
  min-width: 100%;
  border: 0;
  margin: 0;
}
.shittyPopUp {
  p {
    display: inline-block;
    vertical-align: middle;
  }

  button {
    margin: 5px;
  }
}

/* Jesse custom styles */

.underline {
  text-decoration: underline;
}

.limewire_menu_item {
  font-weight: bold;
  display: inline-block;
  padding-right: 10px;
}

.lime_table_name {
  overflow: hidden;
}

.lime_table tr {
  cursor: pointer;
  &:nth-child(even) {
    background-color: #c7d6df;
  }
  &:hover {
    background-color: #b9cad4;
  }
}

.field-row{
  margin:0 auto 10px;
}
.media-player-button {
  min-width: 25px;
  min-height: 25px;
  margin: 0;
}
.media-player-timeline {
  display: block;
  background: #ffffff !important;
  box-shadow: inset -1px -1px #ffffff, inset 1px 1px #808080,
    inset -2px -2px #dfdfdf, inset 2px 2px #0a0a0a;
  height: 17px;
  padding:3px 5px;
  &::-webkit-slider-runnable-track,
  &::-moz-range-track {
    visibility: hidden;
  }
}
.media-player-volume {
  display: flex;
  flex-direction: row;
}
