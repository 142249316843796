.glitch {
    position: relative;
    animation-name: glitch-effect;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
}

@keyframes glitch-effect {
    0% {
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    10%, 100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}